<script setup>

import { defineModel, defineProps, reactive, watch } from 'vue'

const value = defineModel({required: true, type: Number})

const props = defineProps({
    prefixId: {type: String},
    readonly: {type: Boolean}
})

const setInputTime = () => {
    if (typeof value.value != 'number')
        return {hours: null, mins: null, secs: null}

    const hours = parseInt(value.value / 60 / 60)
    const mins = parseInt(value.value / 60) % 60
    const secs = value.value % 60

    return {hours, mins, secs}
}

const inputTime = reactive(setInputTime())

const setAttrs = (field) => {
    const attrs = {readonly: props.readonly}

    if (props.prefixId) {
        const camelCase = /^[A-Za-z0-9]*[A-Z][A-Za-z0-9]*$/.test(props.prefixId)

        if (camelCase)
            attrs.id = props.prefixId + field[0].toUpperCase() + field.slice(1)
        else
            attrs.id = `${props.prefixId}-${field}`
    }

    return attrs
}

const inputAttr = {
    hours: setAttrs('hours'),
    mins: setAttrs('mins'),
    secs: setAttrs('secs')
}

watch(inputTime, (newInputTime) => {
    const hours = newInputTime.hours || 0
    const mins = newInputTime.mins || 0
    const secs = newInputTime.secs || 0

    value.value = (((hours * 60) + mins) * 60) + secs
})

</script>
<template>
    <div class="input-group mb-3" style="width: 36ch;">
        <input type="number" class="form-control" placeholder="HH" size="2" min="0" max="99"
               v-bind="inputAttr.hours" v-model="inputTime.hours"/>
        <span class="input-group-text">:</span>
        <input type="number" class="form-control" placeholder="MM" size="2" min="0" max="59"
               v-bind="inputAttr.mins" v-model="inputTime.mins"/>
        <span class="input-group-text">:</span>
        <input type="number" class="form-control" placeholder="SS" size="2" min="0" max="59"
               v-bind="inputAttr.secs" v-model="inputTime.secs"/>
    </div>
</template>