<script setup>
import 'bootstrap/dist/js/bootstrap.bundle'
import { inject } from "vue";
import LinkItem from './components/LinkItem.vue'

const HCP = inject('HCP')
</script>
<style>
@import'~bootstrap/dist/css/bootstrap.css';
</style>
<template>
  <h1>Home Control Panel</h1>
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button"
              data-bs-toggle="collapse" data-bs-target="#navbarTogglerHCP">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerHCP">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <LinkItem to="/">Dashboard</LinkItem>
          <LinkItem v-if="!HCP.apiAuth.value" to="/connect">Connect</LinkItem>
          <LinkItem v-if="HCP.apiAuth.value" to="/door">Doors</LinkItem>
          <LinkItem v-if="HCP.apiAuth.value" to="/plug">Power Plug</LinkItem>
          <LinkItem v-if="HCP.isSuper.value" to="/user">Users</LinkItem>
          <LinkItem to="/settings">Settings</LinkItem>
        </ul>
      </div>
    </div>
  </nav>
  <main>
    <router-view></router-view>
  </main>
</template>