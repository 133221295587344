<script setup>
import ShowDateTimeDisplay from '@/components/ShowDateTimeDisplay.vue'
import DoorLogModal from '@/components/DoorLogModal.vue'
import { inject, reactive, ref, watch } from 'vue'

const HCP = inject('HCP')
const SeenDoor = HCP.devices.locks
const ShowLogQuery = reactive({start: null, end: null, deviceId: null})
const ShowLogModal = ref(false)
const LogEntries = ref(null)

watch(ShowLogQuery, async (query) => {
    if (query.start == null || query.end == null || query.deviceId == null) {
        ShowLogModal.value = false
        LogEntries.value = null
        return
    }
    LogEntries.value = await HCP.deviceDoorLog(query.deviceId, query.start, query.end, 'any')
})

const showBattery = (door) => {
    const battery = parseInt(door.battery)

    if (isNaN(battery))
        return "Not Available"

    return `${battery}%`
}

const showLinkQuality = (door) => {
    const linkquality = parseInt(door.linkquality)

    if (isNaN(linkquality))
        return "Not Available"

    const percent = parseInt((linkquality / 255) * 100)

    return `${linkquality} (${percent}%)`
}

const classBattery = (door) => {
    const battery = parseInt(door.battery)
    const theme = (
        isNaN(battery) ? "secondary" : (
            battery > 40 ? "primary" : (battery > 20 ? "warning" : "danger")))
    
    return [
        `text-${theme}-emphasis`, `bg-${theme}-subtle`, 'd-none', 'd-sm-table-cell'
    ]
}

const classLinkQuality = (door) => {
    const linkquality = parseInt(door.linkquality)
    const theme = (
        isNaN(linkquality) ? "secondary" : (
            linkquality > 127 ? "primary" : (linkquality > 64 ? "warning" : "danger")))

    return [
        `text-${theme}-emphasis`, `bg-${theme}-subtle`, 'd-none', 'd-sm-table-cell'
    ]
}

const showLogs = async (deviceId) => {
    const now = Date.now()

    ShowLogQuery.deviceId = deviceId
    ShowLogQuery.start = new Date(now - (7 * 24 * 60 * 60 * 1000))
    ShowLogQuery.end = new Date(now)

    ShowLogModal.value = true
}

</script>
<template>
    <h2>Door Controls</h2>
    <table class="table">
        <thead>
            <tr>
                <th>Identifier</th>
                <th class="d-none d-sm-table-cell">Last Seen</th>
                <th>Lock/Unlock</th>
                <th class="d-none d-sm-table-cell">Battery</th>
                <th class="d-none d-sm-table-cell">Link</th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="!Object.keys(SeenDoor).length" class="table-info">
                <td colspan="5" class="text-center font-weight-bold">No doors identified yet.</td>
            </tr>
            <tr v-for="(door, name) in SeenDoor" :key="name">
                <td>
                    <a href="#"
                       class="link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
                       @click.prevent="showLogs(name)">{{ name }}</a>
                </td>
                <td class="d-none d-sm-table-cell"><ShowDateTimeDisplay :date="new Date(door.lastUpdateAt * 1000)"/></td>
                <td>
                    <button v-if="door.data.lock_state == 'locked'" type="button"
                            class="btn btn-success"
                            @click="HCP.deviceUnlock(name)">Unlock</button>
                    <button v-if="door.data.lock_state == 'unlocked'" type="button"
                            class="btn btn-primary"
                            @click="HCP.deviceLock(name)">Lock</button>
                    <span class="secondary" v-if="door.lock_state == 'not_fully_locked'">
                        Faulty:
                        <button type="button"
                                class="btn btn-success"
                                @click="HCP.deviceUnlock(name)">Try Unlock</button>
                        <button type="button"
                                class="btn btn-primary"
                                @click="HCP.deviceLock(name)">Try Lock</button>
                    </span>
                </td>
                <td :class="classBattery(door.data)">{{ showBattery(door.data) }}</td>
                <td :class="classLinkQuality(door.data)">{{ showLinkQuality(door.data) }}</td>
            </tr>
        </tbody>
    </table>
    <DoorLogModal v-if="ShowLogModal" :logs="LogEntries" v-model="ShowLogQuery"/>
</template>