
import { reactive, ref, watch } from 'vue'
import mqtt from 'mqtt'
import { browserStreamBuilder } from 'mqtt/lib/connect/ws'

function removeExpired(devicesList, expireDevice) {
    const now = parseInt(Date.now() / 1000)
    for (let deviceName in devicesList)
        if ((now - devicesList[deviceName].lastUpdateAt) > expireDevice)
            delete devicesList[deviceName]
    return devicesList
}

export class APIError extends Error {
    constructor(status, response) {
        super(`API failed with status: ${status}`)
        this.status = status
        this.response = response
    }
}

export class APIPaginator {
    constructor(response, urlOpener) {
        this.response = response
        this.urlOpener = urlOpener
        this.loading = false
    }

    async next() {
        if (!this.hasNext || this.loading)
            return null
        this.loading = true
        try {
            this.response = await this.urlOpener(this.response.links.next)
            return this.response
        } finally {
            this.loading = false
        }
    }

    async previous() {
        if (!this.hasPrevious || this.loading)
            return null
        this.loading = true
        try {
            this.response = await this.urlOpener(this.response.links.prev)
            return this.response
        } finally {
            this.loading = false
        }
    }

    get hasNext() {
        return this.response.links && 'next' in this.response.links
    }

    get hasPrevious() {
        return this.response.links && 'prev' in this.response.links
    }
}

export class HomeControlPanel {
    constructor(app_options) {
        const localStorage = window.localStorage
        this.mqttAuth = ref(false)
        this.apiAuth = ref(false)
        this.isSuper = ref(false)
        this.connAckCode = ref(0)
        this.lastError = ref(null)
        this.reauthTimer = null
        this.options = app_options
        this.devices = {
            locks: reactive(
                removeExpired(
                    JSON.parse(localStorage.getItem('devices_locks') || '{}'),
                    this.options.expire_device)),
            plugs: reactive(
                removeExpired(
                    JSON.parse(localStorage.getItem('devices_plugs') || '{}'),
                    this.options.expire_device))
        }
        watch(
            this.devices.locks,
            (locks) => localStorage.setItem('devices_locks', JSON.stringify(locks)))
        watch(
            this.devices.plugs,
            (plugs) => localStorage.setItem('devices_plugs', JSON.stringify(plugs)))
        this.client = new mqtt.MqttClient(
            (client) => {
                delete this.options.mqtt_options.hostname  // delete cached host
                return browserStreamBuilder(client, this.options.mqtt_options)},
            this.options.mqtt_options)
        this.client.on('connect', (connack) => {
            this.connAckCode.value = connack.returnCode
            if (connack.returnCode == 0) {
                this.mqttAuth.value = true
                if (!connack.sessionPresent) {
                    let devicesPromise = []
                    this.client.subscribe('zigbee2mqtt/+')
                    for (const deviceId in this.devices.locks)
                        devicesPromise.push(this.deviceDoorLastLog(deviceId))
                    Promise.all(devicesPromise).then((devices) => {
                        devices.forEach((device) => {
                            const lastUpdateAt = parseInt(
                                (new Date(device.timestamp)).getTime() / 1000)
                            if (lastUpdateAt > this.devices.locks[device.identifier].lastUpdateAt)
                                Object.assign(
                                    this.devices.locks[device.identifier], {
                                        lastUpdateAt,
                                        data: {
                                            lock_state: device.state,
                                            battery: device.battery,
                                            linkquality: device.linkquality,
                                        }
                                    }
                                )
                        })
                    })
                    devicesPromise = []
                    for (const deviceId in this.devices.plugs)
                        devicesPromise.push(this.devicePlugLastLog(deviceId))
                    Promise.all(devicesPromise).then((devices) => {
                        devices.forEach((device) => {
                            const lastUpdateAt = parseInt(
                                (new Date(device.timestamp)).getTime() / 1000)
                            if (lastUpdateAt > this.devices.plugs[device.identifier].lastUpdateAt)
                                Object.assign(
                                    this.devices.plugs[device.identifier], {
                                        lastUpdateAt,
                                        data: {
                                            state: device.state,
                                            freq: device.freq,
                                            consumption: device.consumption,
                                            current: device.current,
                                            voltage: device.voltage,
                                            linkquality: device.linkquality,
                                        }
                                    }
                                )
                        })
                    })
                }
            }
        })
        this.client.on('close', () => {
            this.mqttAuth.value = false
        })
        this.client.on('message', (topic, message) => {
            const matchTopic = topic.match(/^zigbee2mqtt\/([^/]+)/)

            if (matchTopic == null)
                return

            const deviceState = JSON.parse(message.toString())

            if ('lock_state' in deviceState)
                this.devices.locks[matchTopic[1]] = {
                    lastUpdateAt: parseInt(Date.now() / 1000),
                    data: deviceState
                }
            else if ('voltage' in deviceState &&
                     (deviceState.state == 'ON' || deviceState.state == 'OFF'))
                this.devices.plugs[matchTopic[1]] = {
                    lastUpdateAt: parseInt(Date.now() / 1000),
                    data: deviceState
                }
        })
        this.client.on('error', (err) => this.lastError.value = err.message)
    }

    async sendApiRequest(endpoint, method, data, fmt) {
        if (!fmt)
            fmt = typeof data == 'string' ? 'text' : 'json'
        let contentType
        switch (fmt) {
        case 'json':    contentType = 'application/json';       break;
        case 'text':    contentType = 'text/plain';             break;
        }
        const headers = new Headers({
            'Accept': 'application/json',
            'Content-Type': contentType,
        })
        let body
        switch (fmt) {
        case 'json':    body = JSON.stringify(data);            break;
        case 'text':    body = data;                            break;
        }

        if (this.options.auth_token)
            headers.append('Authorization', `Bearer ${this.options.auth_token}`)
        if (endpoint[0] != '/')
            endpoint = '/' + endpoint

        const response = await fetch(
            this.options.api_base_url + endpoint, {
                method,
                headers,
                body,
            }
        )
        if (!response.ok) {
            let responseData = await response.text()

            try {
                responseData = JSON.parse(responseData)
            } catch (err) {
                console.error(err)
            }
            throw new APIError(response.status, responseData)
        }
        return response
    }

    

    async apiCall(endpoint, method, data, fmt) {
        const response = await this.sendApiRequest(endpoint, method, data, fmt)

        return response.json()
    }

    async apiCallPages(endpoint, method, data, fmt) {
        const parseLinkHeader = (header) => {
            const links = {}

            header.split(',').forEach((link) => {
                const parts = link.split(';')
                const url = parts[0].trim().slice(1, -1)
                const attrs = {}
                parts.slice(1).forEach((part) => {
                    part = part.trim()
                    const delim = part.indexOf('=')
                    if (delim < 1)
                        throw Error('Invalid Link header')
                    const key = part.slice(0, delim)
                    let value = part.slice(delim + 1)
                    if (value[0] == '"') {
                        if (value[value.length - 1] != '"')
                            throw Error('Invalid Link header')
                        value = value.slice(1, -1)
                    }
                    attrs[key] = value
                })
                if (attrs.rel)
                    links[attrs.rel] = url
            })
            return links
        }

        const response = await this.sendApiRequest(endpoint, method, data, fmt)
        const links = (
            response.headers.has('Link') ?
            parseLinkHeader(response.headers.get('Link')) :
            undefined)
        const body = await response.json()

        return {body, links}
    }

    async* paginateApiCall(endpoint, method, data, fmt) {
        let response = await this.apiCallPages(endpoint, method, data, fmt)

        yield response.body

        while (response.links && response.links.next) {
            response = await this.apiCallPages(response.links.next)
            yield response.body
        }
    }

    async getExpireTokenTime() {
        if (!this.options.expire_token) {
            const sessionInfo = await this.getSessionInfo()

            this.options.expire_token = sessionInfo.expireAt.getTime() / 1000
        }

        return this.options.expire_token
    }

    async reauthenticateTimer() {
        const reauthFunc = async () => {
            this.reauthTimer = null
            await this.authAndConnect(true)
        }

        if (this.reauthTimer) {
            clearTimeout(this.reauthTimer)
            this.reauthTimer = null
        }
        if (!this.options.auth_auto_refresh)
            return this.reauthTimer

        const now = Date.now() / 1000
        const expireToken = await this.getExpireTokenTime()
        const timeLeft = (expireToken - now)

        if (timeLeft < 0)
            return this.reauthTimer
        if (timeLeft <= this.options.renew_token)
            this.reauthTimer = setTimeout(reauthFunc, parseInt((timeLeft / 2) * 1000))
        else
            this.reauthTimer = setTimeout(reauthFunc, parseInt((timeLeft - this.options.renew_token) * 1000))

        return this.reauthTimer
    }

    async authenticate(reauth) {
        this.apiAuth.value = false
        if (this.options.auth_token) {
            let userInfo
            try {
                userInfo = await this.whoami()
            } catch (err) {
                if (err instanceof APIError && err.status == 401)
                    userInfo = null
                else
                    throw err
            }
            if (userInfo && !reauth) {
                this.apiAuth.value = true
                this.isSuper.value = userInfo.isSuper
                return userInfo.user
            }
        }

        const authData = {
            username: this.options.username,
            password: this.options.password}
        const responseData = await this.apiCall('/auth/token', 'POST', authData)
        
        if (!responseData.valid)
            throw new Error('Wrong username/password match')

        this.options.auth_token = responseData.token
        this.options.expire_token = null

        const userInfo = await this.whoami()
        this.apiAuth.value = true
        this.isSuper.value = userInfo.isSuper

        return userInfo.user
    }

    async whoami() {
        const responseData = await this.apiCall('/auth/me')

        return {
            user: responseData.username,
            isActive: responseData.is_active,
            isSuper: responseData.is_super,
        }
    }

    async getSessionInfo() {
        const responseData = await this.apiCall('/auth/session')

        return {
            user: responseData.user,
            expireAt: new Date(responseData.expire_at),
        }
    }

    async getAllUsers() {
        const responseData = await this.apiCall('/user')

        return responseData
    }

    async getUser(user) {
        if (!/^\w+$/.test(user))
            return null

        try {
            const responseData = await this.apiCall(`/user/${user}`)

            return {
                user: responseData.username,
                isActive: responseData.is_active,
                isSuper: responseData.is_super,
            }
        } catch (err) {
            if (err.status == 404)
                return null
            throw err
        }
    }

    async createUser(userInfo) {
        await this.apiCall('/user', 'POST', {
            username: userInfo.user,
            raw_password: userInfo.password,
            is_active: userInfo.isActive,
            is_super: userInfo.isSuper,
        })
    }

    async setUserPassword(user, password) {
        const responseData = await this.apiCall(
            `/user/${user}/password`, 'PUT', {raw_password: password})
        
        return responseData.status
    }

    async enableUser(user) {
        const responseData = await this.apiCall(
            `/user/${user}/activate`, 'PUT', {is_active: true})

        return responseData.status
    }

    async disableUser(user) {
        const responseData = await this.apiCall(
            `/user/${user}/activate`, 'PUT', {is_active: false})

        return responseData.status
    }

    async setSuperUser(user) {
        const responseData = await this.apiCall(
            `/user/${user}/super`, 'PUT', {is_super: true})

        return responseData.status
    }

    async unsetSuperUser(user) {
        const responseData = await this.apiCall(
            `/user/${user}/super`, 'PUT', {is_super: false})

        return responseData.status
    }

    async setMyPassword(changePassword) {
        const responseData = await this.apiCall(
            '/auth/password', 'POST', {
                old_password: changePassword.oldPassword,
                new_password: changePassword.newPassword})
        
        return responseData.status
    }

    connect() {
        if (this.client.connected)
            this.client.reconnect()
        else
            this.client.connect()
    }

    async authAndConnect(reauth) {
        await this.authenticate(reauth)

        if (this.options.auth_auto_refresh) {
            const timerId = await this.reauthenticateTimer()

            if (!timerId)
                console.error('Failed to set refresh token timer')
        }

        if (this.apiAuth.value && this.options.auth_token) {
            this.options.mqtt_options.username = this.options.auth_token
            this.options.mqtt_options.password = 'JWT'
            this.connect()
        }
    }

    deviceLock(deviceId) {
        if (!this.mqttAuth.value)
            throw new Error('User not authenticated')
        if (!(deviceId in this.devices.locks))
            throw new Error(`Lock device not found: ${deviceId}`)
        this.client.publish(`zigbee2mqtt/${deviceId}/set`, JSON.stringify({state: 'LOCK'}))
    }

    deviceUnlock(deviceId) {
        if (!this.mqttAuth.value)
            throw new Error('User not authenticated')
        if (!(deviceId in this.devices.locks))
            throw new Error(`Lock device not found: ${deviceId}`)
        this.client.publish(`zigbee2mqtt/${deviceId}/set`, JSON.stringify({state: 'UNLOCK'}))
    }

    async deviceDoorLastLog(deviceId) {
        const responseData = await this.apiCall(`/log/door/${deviceId}/last`)

        responseData.timestamp = new Date(responseData.timestamp)
        return responseData
    }

    async deviceDoorLog(deviceId, startDate, endDate, byAction) {
        const q = [
            ['time_start', encodeURIComponent(startDate.toISOString())].join('='),
            ['time_stop', encodeURIComponent(endDate.toISOString())].join('=')
        ]
        if (byAction)
            q.push(['action', encodeURIComponent(byAction)].join('='))
        const qs = q.join('&')
        const apiEndpoint = `/log/door/${deviceId}`
        const response = await this.apiCallPages(`${apiEndpoint}?${qs}`)

        return new APIPaginator(response, (pageUrl) => {
            const resolveUrl = new URL(pageUrl, `schema:${apiEndpoint}`)
            return this.apiCallPages(resolveUrl.toString().slice(7))
        })
    }

    deviceOn(deviceId, settings) {
        if (!this.mqttAuth.value)
            throw new Error('User not authenticated')
        if (!(deviceId in this.devices.plugs))
            throw new Error(`Switch device not found: ${deviceId}`)
        if (typeof settings != 'object')
            settings = {}
        this.client.publish(
            `zigbee2mqtt/${deviceId}/set`, JSON.stringify({state: 'ON', ...settings}))
    }

    deviceOff(deviceId, settings) {
        if (!this.mqttAuth.value)
            throw new Error('User not authenticated')
        if (!(deviceId in this.devices.plugs))
            throw new Error(`Switch device not found: ${deviceId}`)
        if (typeof settings != 'object')
            settings = {}
        this.client.publish(
            `zigbee2mqtt/${deviceId}/set`, JSON.stringify({state: 'OFF', ...settings}))
    }

    async devicePlugLastLog(deviceId) {
        const responseData = await this.apiCall(`/log/plug/${deviceId}/last`)

        responseData.timestamp = new Date(responseData.timestamp)
        return responseData
    }

    async devicePlugLog(deviceId, startDate, endDate) {
        const q = [
            ['time_start', encodeURIComponent(startDate.toISOString())].join('='),
            ['time_stop', encodeURIComponent(endDate.toISOString())].join('=')
        ]
        const qs = q.join('&')
        const apiEndpoint = `/log/plug/${deviceId}`
        const response = await this.apiCallPages(`${apiEndpoint}?${qs}`)

        return new APIPaginator(response, (pageUrl) => {
            const resolveUrl = new URL(pageUrl, `schema:${apiEndpoint}`)
            return this.apiCallPages(resolveUrl.toString().slice(7))
        })
    }
}
