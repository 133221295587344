<script setup>
import ShowDateTimeDisplay from '@/components/ShowDateTimeDisplay.vue'
import PlugSchedModal from '@/components/PlugSchedModal.vue'
import { inject, reactive } from 'vue'

const HCP = inject('HCP')
const SeenPlug = HCP.devices.plugs
const ShowPlugSched = reactive({name: null, timer: 0})

const showLinkQuality = (plug) => {
    const linkquality = parseInt(plug.linkquality)

    if (isNaN(linkquality))
        return "Not Available"

    const percent = parseInt((linkquality / 255) * 100)

    return `${linkquality} (${percent}%)`
}

const classLinkQuality = (plug) => {
    const linkquality = parseInt(plug.linkquality)
    const theme = (
        isNaN(linkquality) ? "secondary" : (
            linkquality > 127 ? "primary" : (linkquality > 64 ? "warning" : "danger")))

    return [
        `text-${theme}-emphasis`, `bg-${theme}-subtle`, 'd-none', 'd-sm-table-cell'
    ]
}

</script>
<template>
    <h2>Power Plug Controls</h2>
    <table class="table">
        <thead>
            <tr>
                <th>Identifier</th>
                <th class="d-none d-sm-table-cell">Last Seen</th>
                <th>On/Off</th>
                <th class="d-none d-sm-table-cell">Frequency (Hz)</th>
                <th class="d-none d-sm-table-cell">Consumption (kWh)</th>
                <th class="d-none d-sm-table-cell">Current (A)</th>
                <th class="d-none d-sm-table-cell">Voltage (V)</th>
                <th class="d-none d-sm-table-cell">Link</th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="!Object.keys(SeenPlug).length" class="table-info">
                <td colspan="8" class="text-center font-weight-bold">No doors identified yet.</td>
            </tr>
            <tr v-for="(plug, name) in SeenPlug" :key="name">
                <td>{{ name }}</td>
                <td class="d-none d-sm-table-cell"><ShowDateTimeDisplay :date="new Date(plug.lastUpdateAt * 1000)"/></td>
                <td>
                    <div v-if="plug.data.state == 'OFF'" class="dropdown">
                        <button type="button"
                                class="btn btn-success dropdown-toggle"
                                data-bs-toggle="dropdown">ON</button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item"
                                   href="#" @click.prevent="HCP.deviceOn(name)">Without Timer</a></li>
                            <li><a class="dropdown-item"
                                   href="#" @click.prevent="ShowPlugSched.name = name">With Timer</a></li>
                        </ul>
                    </div>
                    <button v-if="plug.data.state == 'ON'" type="button"
                            class="btn btn-secondary"
                            @click="HCP.deviceOff(name)">OFF</button>
                </td>
                <td class="d-none d-sm-table-cell">{{ plug.data.freq }}</td>
                <td class="d-none d-sm-table-cell">{{ plug.data.consumption }}</td>
                <td class="d-none d-sm-table-cell">{{ plug.data.current }}</td>
                <td class="d-none d-sm-table-cell">{{ plug.data.voltage }}</td>
                <td :class="classLinkQuality(plug.data)">{{ showLinkQuality(plug.data) }}</td>
            </tr>
        </tbody>
    </table>
    <PlugSchedModal v-if="ShowPlugSched.name" v-model="ShowPlugSched"/>
</template>