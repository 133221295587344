<script setup>

import ElapsedTimeField from '@/components/ElapsedTimeField'
import { computed, defineProps, defineModel } from 'vue'


const props = defineProps({
    label: {type: String, required: true},
    fieldId: {type: String, required: true},
    fieldType: {type: String, default: "text"},
    fieldPattern: {type: String},
    fieldRO: {type: Boolean},
    labelSize: {type: Number, default: 2},
    fieldSize: {type: Number, default: 10}
})

const fieldValue = defineModel({required: true})

const fieldAttr = {
    type: props.fieldType,
    id: props.fieldId,
    pattern: props.fieldPattern,
    readonly: props.fieldRO ? props.fieldRO : undefined,
}

const fieldAttrElapsedTime = {
    prefixId: props.fieldId,
    readonly: props.fieldRO ? props.fieldRO : undefined,
}

const inputType = computed(() => {
    switch (props.fieldType) {
    case "checkbox":
    case "radio":
        return "form-check-input"
    default:
        return "form-control"
    }
})

</script>

<template>
    <div class="mb-3 row">
        <label :for="fieldId" :class="['col-sm-' + labelSize, 'col-form-label']">{{ label }}</label>
        <div :class="['col-sm-' + fieldSize]">
            <ElapsedTimeField v-if="props.fieldType == 'time-elapsed'" v-bind="fieldAttrElapsedTime" v-model="fieldValue"/>
            <input v-else v-bind="fieldAttr" :class="inputType" v-model="fieldValue"/>
        </div>
    </div>
</template>// form-check-input