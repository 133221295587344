<script setup>
import FormField from '@/components/FormField'
import { defineModel, inject } from 'vue'

const HCP = inject('HCP')

const ShowPlugSched = defineModel()

const closeModal = () => {
    ShowPlugSched.value.name = null
    ShowPlugSched.value.timer = 0
}

const timerPlug = () => {
    HCP.deviceOn(ShowPlugSched.value.name, {on_time: ShowPlugSched.value.timer, off_wait_time: 30})
    closeModal()
}

</script>
<template>
<Teleport to="body">
    <div class="modal show" id="plugSchedModal" tabindex="-1" role="dialog"
         aria-labelledby="plugSchedModalLabel" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="plugSchedModalLabel">Schedule Turn Off</h5>
                    <button type="button" class="btn-close"
                            aria-label="Close" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <fieldset class="border p-2">
                            <legend class="float-none w-auto p-2">Set Timer</legend>
                            <FormField label="Turn off after" fieldId="timerPlugTurnOff"
                                       fieldType="time-elapsed" v-model="ShowPlugSched.timer"/>
                            <button type="button" class="btn btn-success"
                                    @click="timerPlug">ON</button>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop show"></div>
</Teleport>
</template>